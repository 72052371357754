import ColorSelectorNode from "../custom-node/ColorSelectorNode";
import TextNode from "../custom-node/TextNode";
import ApplicationNode from "../custom-node/ApplicationNode";
import VerifyNode from "../custom-node/VerifyNode";
import DataNode from "../custom-node/DataNode";
import CardNode from "../custom-node/CardNode";
import TnCNode from "../custom-node/TnCNode";

export const workflowNodeComponentTypes = {
  selectorNode: ColorSelectorNode,
  textNode: TextNode,
  // imageNode: ImageNode,
  tncNode: TnCNode,
  applicationNode: ApplicationNode,
  verifyNode: VerifyNode,
  // fileNode: FileNode,
  dataNode: DataNode,
  startNode: CardNode,
  reviewNode: CardNode,
  endNode: CardNode,
  preScreenNode: CardNode,
  eligibilityNode: CardNode
};

// export default useMemo(workflowNodeTypes);