export const advancedWFNodes = [
  {
    id: 'start-01',
    // sourcePosition: 'right',
    type: 'startNode',
    data: {
      label: 'START',
      icon: 'start',
      description: 'When Apply Clicked',
      color: 'info',
      // value: 'success'
    },
    position: { x: 0, y: 39 },
  },
  {
    id: 'tc-01',
    sourcePosition: 'right',
    targetPosition: 'left',
    type: 'tncNode',
    // style: { border: '1px solid #777', padding: 10 },
    data: {
      label: 'T&C',
      icon: 'note_alt',
      description: 'Display T&C',
      color: 'warning',
      value: 'success'
    },
    position: { x: 160, y: 5 },
  },
  {
    id: 'verify-01',
    sourcePosition: 'right',
    targetPosition: 'left',
    type: 'verifyNode',
    // style: { border: '1px solid #777', padding: 10 },
    data: {
      label: 'Verification',
      icon: 'person',
      description: 'ID Verification',
      color: 'success',
      // value: 'success'
    },
    position: { x: 450, y: 39 },
  },
  {
    id: 'submission-01',
    sourcePosition: 'right',
    targetPosition: 'left',
    type: 'applicationNode',
    data: {
      label: 'Application',
      icon: 'post_add',
      description: 'Submission',
      color: 'primary',
      // value: 'success'
    },
    position: { x: 600, y: 5 },
  },
  {
    id: 'data-01',
    sourcePosition: 'right',
    targetPosition: 'left',
    type: 'dataNode',
    data: {
      label: 'JSON',
      icon: 'data_object',
      description: 'Convert & Send',
      color: 'warning',
      // value: 'success'
    },
    position: { x: 980, y: 39 },
  },
  {
    id: 'end-01',
    sourcePosition: 'right',
    targetPosition: 'left',
    type: 'endNode',
    data: {
      label: 'END',
      icon: 'check_circle_outline',
      description: 'Back-Office Server',
      color: 'success',
      // value: 'success'
    },
    position: { x: 1200, y: 39 },
  }
];

export const advancedWFEdges = [
  {
    id: 'horizontal-e1-2',
    source: 'start-01',
    target: 'tc-01',
    sourceHandle: 'b',
    type: 'smoothstep',
    style: { stroke: '#15b615' },
    // label: 'On Apply Click',
    animated: true,
  },
  {
    id: 'horizontal-e1-3',
    source: 'tc-01',
    target: 'verify-01',
    sourceHandle: 'b',
    type: 'smoothstep',
    style: { stroke: '#55f' },
    // label: 'edge label',
    animated: true,
  },
  {
    id: 'horizontal-e2-3',
    source: 'verify-01',
    target: 'submission-01',
    sourceHandle: 'b',
    type: 'smoothstep',
    style: { stroke: '#55f' },
    // label: 'edge label',
    animated: true,
  },
  {
    id: 'horizontal-e1-4',
    source: 'submission-01',
    target: 'data-01',
    sourceHandle: 'b',
    type: 'smoothstep',
    style: { stroke: '#55f' },
    label: 'Case Review',
    animated: true,
  },
  {
    id: 'horizontal-e1-5',
    source: 'data-01',
    target: 'end-01',
    sourceHandle: 'b',
    type: 'smoothstep',
    style: { stroke: '#1010b9' },
    label: 'Send to Backoffice',
    animated: true,
  }
];

export const advancedWFSteps = [
  {
    source: 'start-01',
    sourceType: 'start',
    target: 'tc-01',
    targetType:'tnc',
    seq: 0,
  },
  {
    source: 'tc-01',
    sourceType:'tnc',
    target: 'submission-01',
    targetType:'application',
    seq: 1,
  },
  {
    source: 'submission-01',
    sourceType:'application',
    target: 'data-01',
    targetType:'data',
    seq: 2,
  },
  {
    source: 'data-01',
    sourceType: 'data',
    target: 'end-01',
    targetType:'end',
    seq: 3,
  },

];
