/* eslint-disable no-unused-vars */

import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// typed-js
import Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// MKit React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";
// Images
import bgImage from "assets/images/cs-bg-dark.svg";
import Rellax from "rellax";

function HeaderHome() {
  const navigate = useNavigate();
  const typedJSRef = useRef(null);

  // Setting up typedJS
  // useEffect(() => {
  //   const typedJS = new Typed(typedJSRef.current, {
  //     strings: ["Data Submission", "Document Generation","Task Management", "Process Automation"],
  //     typeSpeed: 70,
  //     backSpeed: 30,
  //     backDelay: 900,
  //     startDelay: 500,
  //     loop: true,
  //   });
  //
  //   return () => typedJS.destroy();
  // }, []);

  return (
    <MKBox component="header" position="relative" height="100%" >

      <MKBox
        pt={6}
        display="flex"
        alignItems="center"
        // minHeight="100%"
        minHeight="85vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.4),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="auto"
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"]
                }
              })}
            >
              Reduce Workload With Simplified Automation.
            </MKTypography>

            {/* <MKTypography */}
            {/*   variant="h2" */}
            {/*   color="white" */}
            {/*   sx={({ breakpoints, typography: { size } }) => ({ */}
            {/*     [breakpoints.down("md")]: { */}
            {/*       fontSize: size["3xl"], */}
            {/*     }, */}
            {/*   })} */}
            {/* > */}
            {/*    */}
            {/*    <span ref={typedJSRef} /> */}
            {/* </MKTypography> */}

            {/* <MKTypography */}
            {/*   variant="h1" */}
            {/*   color="white" */}
            {/*   sx={({ breakpoints, typography: { size } }) => ({ */}
            {/*     [breakpoints.down("md")]: { */}
            {/*       fontSize: size["3xl"], */}
            {/*     }, */}
            {/*   })} */}
            {/* > */}
            {/*   Save time to breathe. */}
            {/* </MKTypography> */}
            <MKTypography variant="BODY1" color="white" mt={4} mb={2} px={{ xs: 3, lg: 6 }}>
              Easy-to-use data collection, case management, process automation, document generation and lot more for your teams. Save time to breathe - no dev required.
              {/* No-Code, Fast-to-Market, Streamlined Experience */}
              {/* Streamlined Customer Information Intake, Secure Collaboration, and Effortless Workflow Design without a tech team! */}
            </MKTypography>
            {/* <MKButton color="white" onClick={()=>navigate('/contact-us') } >contact us</MKButton> */}
            <Stack direction="row" spacing={1} mt={6} mb={3}>
              <MKButton variant="gradient" color="primary" size={"large"}
                        onClick={()=>navigate('/contact-us')}>
                contact us
              </MKButton>
              <MKButton variant="text" color="white" size={"large"}
                        onClick={()=>navigate('/contact-us')}
                        // href={'https://app.consen.co/tn4/home'}
                        // target="_blank"
                        // rel="noreferrer"
              >
                Why CONSEN?&nbsp;
                <Icon fontSize="medium">arrow_right</Icon>
              </MKButton>
            </Stack>
            {/* https://app.consen.co/tn4/home */}


          </Grid>

        </Container>
      </MKBox>
    </MKBox>
  );
}

export default HeaderHome;
