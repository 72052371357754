
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";

// MKit React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKTypography from "../../../components/MKTypography";
import FilledInfoCard from "../../../examples/Cards/InfoCards/FilledInfoCard";

function Purpose() {
  return (
    <MKBox component="section" py={4} mt={8}>
      <Container>
        {/* <Grid container spacing={3} justifyContent={"center"} mt={8}> */}
        {/*   <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}> */}
        {/*     <MKTypography variant="h3" fontWeight="bold" mb={0.5} color={"info"} */}
        {/*                   sx={({ breakpoints, typography: { size } }) => ({ */}
        {/*                     [breakpoints.down("md")]: { */}
        {/*                       fontSize: size["2xl"] */}
        {/*                     } */}
        {/*                   })} */}
        {/*     > */}
        {/*       Integrated Tool Suite, No-Code */}
        {/*     </MKTypography> */}
        {/*     /!* <MKTypography variant="body1" color="text"> *!/ */}
        {/*     /!*   Your Digital Transformation initiatives doesn't have to halt because of IT backlogs anymore. Your non-technical business users can now *!/ */}
        {/*     /!*   build your application intake process. *!/ */}
        {/*     /!* </MKTypography> *!/ */}
        {/*   </Grid> */}
        {/* </Grid> */}

        {/* <MKTypography variant="d4" color="text"> */}
        {/*   68% of consumers abandoned an application because of frictions */}
        {/*   /!* Your Digital Transformation initiatives doesn't have to halt because of IT backlogs anymore. Your non-technical business users can now *!/ */}
        {/*   /!* build your application intake process. *!/ */}

        {/* </MKTypography> */}



        <Grid container spacing={3} justifyContent={"center"}>
          <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}>
            <MKTypography variant="h2" fontWeight="bold" mb={0.5} color={"info"}
                          sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                              fontSize: size["3xl"]
                            }
                          })}
            >
              Give your teams their lunch break back.
              {/* Gain Customer Confidence Delivering a Transparent Experience */}
            </MKTypography>

          </Grid>
          <Grid item xs={12} lg={10} sx={{ textAlign: { xs: "center" } }}>
            <MKTypography variant="body1" color="text">
              {/* Nearly 70% of consumers abandons an application in their digital purchases. */}
              Replace multiple broken tools with CONSEN, the only platform designed to make your team faster—and happier.
              Consen minimizes frictions and deliver seamless experience to both customers and employees.
              {/* Your Digital Transformation initiatives doesn't have to halt because of IT backlogs anymore. Your non-technical business users can now */}
              {/* build your application intake process. */}

            </MKTypography>

          </Grid>

          {/* <Grid item xs={12} lg={12}> */}
          {/* </Grid> */}

          {/* <Grid item xs={12} lg={10} sx={{ textAlign: { xs: "center" } }}> */}
          {/*   <MKTypography variant="h3" fontWeight="bold" mb={0.5} color={"info"} */}
          {/*                 sx={({ breakpoints, typography: { size } }) => ({ */}
          {/*                   [breakpoints.down("md")]: { */}
          {/*                     fontSize: size["2xl"] */}
          {/*                   } */}
          {/*                 })} */}
          {/*   > */}
          {/*     Taking businesses forward through potential challenges on - */}
          {/*   </MKTypography> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} lg={4}> */}
          {/*   <FilledInfoCard */}
          {/*     // variant="gradient" */}
          {/*     color="success" */}
          {/*     icon="schedule" */}
          {/*     title="Enable Fast Submissions" */}
          {/*     description="Application processes should not take too much time from your customers. That's why Consen supports */}
          {/*     various integrations and purpose built connectors. Now, customers can provide only the ncessary information." */}
          {/*     // action={{ */}
          {/*     //   type: "external", */}
          {/*     //   route: "https://www.creative-tim.com/learning-lab/react/overview/material-kit/", */}
          {/*     //   label: "Let's start" */}
          {/*     // }} */}
          {/*   /> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} lg={4}> */}
          {/*   <FilledInfoCard */}
          {/*     // variant="gradient" */}
          {/*     color="error" */}
          {/*     icon="person" */}
          {/*     title="Digital ID Verification" */}
          {/*     description="Consen supports multiple mechanism out-of-the box such as OIDC, to verify ID and Documents. */}
          {/*     Customers can now get a seamless experience while you can collect your compliance details." */}
          {/*     // action={{ */}
          {/*     //   type: "external", */}
          {/*     //   route: "https://www.creative-tim.com/learning-lab/react/datepicker/material-kit/", */}
          {/*     //   label: "Read more" */}
          {/*     // }} */}
          {/*   /> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} lg={4}> */}
          {/*   <FilledInfoCard */}
          {/*     color="info" */}
          {/*     icon="groups" */}
          {/*     title="No Branch Visit" */}
          {/*     description="Giving end-to-end digital options to customers can eliminate the need for a branch visit. */}
          {/*     Ler them apply for your offerings digitally and improve your economics." */}
          {/*     // action={{ */}
          {/*     //   type: "external", */}
          {/*     //   route: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/", */}
          {/*     //   label: "Read more" */}
          {/*     // }} */}
          {/*   /> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} lg={12} mt={6} sx={{ textAlign: { xs: "center" } }}> */}
          {/*   <MKTypography variant="h5" fontWeight="regular" mb={0.5} */}
          {/*                 sx={({ breakpoints, typography: { size } }) => ({ */}
          {/*                   [breakpoints.down("md")]: { */}
          {/*                     fontSize: size["2xl"] */}
          {/*                   } */}
          {/*                 })} */}
          {/*   > */}
          {/*     And above all */}
          {/*   </MKTypography> */}
          {/*   <MKTypography variant="h3" fontWeight="bold" mb={0.5} */}
          {/*                 sx={({ breakpoints, typography: { size } }) => ({ */}
          {/*                   [breakpoints.down("md")]: { */}
          {/*                     fontSize: size["2xl"] */}
          {/*                   } */}
          {/*                 })} */}
          {/*   > */}
          {/*     Consen strive to Minimize Risk */}
          {/*   </MKTypography> */}

          {/* </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Purpose;
