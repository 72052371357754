
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// MKit React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import WorkflowDisplayCard from "../../../custom-components/WorkflowDisplayCard";
import {
  advancedWFEdges,
  advancedWFNodes
} from "../../../custom-components/WorkflowDisplayCard/wf-data/advance-workflow-01";

function SampleWorkflow() {
  const bgImage =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={2}
      // sx={{
      //   backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
      //     `${linearGradient(
      //       rgba(gradients.light.main, 0.1),
      //       rgba(gradients.light.state, 0.8)
      //     )}, url(${bgImage})`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      // }}
    >
      {/* <Container> */}
        <Grid container item xs={12} sx={{ ml: { xs: 0, lg: 6 } }}>
          {/* <MKTypography variant="h4" color="dark" fontWeight="bold"> */}
          {/*   Your Customer, Your Process */}
          {/* </MKTypography> */}
          {/* <MKTypography variant="h1" color="white" mb={1}> */}
          {/*   Complex Documentation */}
          {/* </MKTypography> */}

          <WorkflowDisplayCard name="Sample workflow"
                               initialNodes={advancedWFNodes}
                               initialEdges={advancedWFEdges}/>

          {/* <MKTypography variant="body1" color="dark" opacity={0.8} mb={2}> */}
          {/*   Achieve maximum efficiency with a smooth data flow from intake to final processing. Non-technical users can now leverage intuitive workflow design tools to optimize internal processes. As your business grows, you can be assured that this solution is scalable and adaptable to your evolving needs. */}
          {/* </MKTypography> */}
          <MKTypography
            component="a"
            href="https://www.creative-tim.com/learning-lab/react/overview/material-kit/"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            color="white"
            fontWeight="regular"
            sx={{
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Read docs <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </Grid>
      {/* </Container> */}
    </MKBox>
  );
}

export default SampleWorkflow;
