import { useNavigate } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// MKit React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import step1Img from "assets/images/cs/cs-steps/1.png";
import step2mg from "assets/images/cs/cs-steps/2.png";
import step3Img from "assets/images/cs/cs-steps/3.png";
import step4Img from "assets/images/cs/cs-steps/4.png";
import step5Img from "assets/images/cs/cs-steps/5.png";
import step6Img from "assets/images/cs/cs-steps/6.png";

import urWeb1Img from "assets/images/cs/CS-screens/16.png";
import cs2img from "assets/images/cs-logo/3.svg";
import urSys3Img from "assets/images/cs/cs-steps/3.png";
import bgImage from "../../../assets/images/cs-bg/shadow2.png";

function HowItWorks() {
  const navigate = useNavigate();
  return (
    <MKBox component="section" py={{ xs: 6, lg: 12 }}
           // sx={{
           //   backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
           //     ` url(${bgImage})`,
           //   backgroundSize: "cover",
           //   backgroundPosition: "center",
           // }}
    >
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={1} sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"]
              }
            })}>
              Streamline Your Operations
            </MKTypography>
            <MKTypography variant="h6" mb={2}>
              CONSEN offers businesses a suite of tools, including Form Builder, Integrations with existing systems,
              and case management to streamline data collection, processing and fulfillment operations without coding.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={4} textAlign="right">
            <MKButton variant="gradient" color="warning" onClick={()=>navigate('/contact-us')} >
              Contact Us
            </MKButton>
          </Grid>
        </Grid>

        <Grid container spacing={3} mt={6}>

          {/* <Grid item xs={12} sm={6} lg={3} mb={1}> */}
          {/*   <DefaultInfoCard */}
          {/*     icon={<MKBox component="img" src={urWeb1Img} alt="shopify" width="80%" />} */}

          {/*     title="Your Website / API" */}
          {/*     description="Check out our proven methods, guides, and exercises that help make work better, and people happier." */}
          {/*     small */}
          {/*   /> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={6} lg={6} mb={1}> */}
          {/*   <DefaultInfoCard */}
          {/*     icon={<MKBox component="img" src={cs2img} alt="shopify" width="50%" />} */}

          {/*     title="CONSEN" */}
          {/*     description="Check out our proven methods, guides, and exercises that help make work better, and people happier." */}
          {/*     small */}
          {/*   /> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={6} lg={3} mb={1}> */}
          {/*   <DefaultInfoCard */}
          {/*     icon={<MKBox component="img" src={urSys3Img} alt="shopify" width="80%" />} */}

          {/*     title="Optional: Your / External System" */}
          {/*     description="Check out our proven methods, guides, and exercises that help make work better, and people happier." */}
          {/*     small */}
          {/*   /> */}
          {/* </Grid> */}

          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step1Img} alt="Specify Data Requirements" width="50%" />}

              title="1. Build Application with Drag-n-Drop"
              description="Design your application for customers - forms, files requirements to capture information seamlessly with our user-friendly application design tools."

            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step2mg} alt="Define Workflow / Process" width="50%" />}

              title="2. Define Workflow / Process"
              description="Pre-screening, T&C, Eligibility check*, Consent, Validations and Verifications* and many others, define your workflow that works for your business."

            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step3Img} alt="Configure Offerings" width="50%" />}
              title="3. Setup Your Offerings"
              description="List and configure your offerings so that Consen knows what to request from customers. Publish, and you are ready for submissions!"

            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step4Img} alt="Application Start" width="50%" />}
              title="4. Use Submit / Apply Button"
              description="Customers or Employees can initiate from Consen.co console, landing page, or customer portal. "

            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step5Img} alt="Submission Review" width="50%" />}
              title="5. Review Submissions"
              description="Once information has been submitted, your employees / agents can review, collaborate and process."

            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step6Img} alt="Optional: Data Exchange" width="50%" />}
              title="6. Optional: Data Exchange"
              description="Optionally, finalized submission data can be sent to your configured destination for further processing or fullfillment."

            />
          </Grid>
          {/* <Grid item xs={12} sm={6} lg={4} mb={3}> */}
          {/*   <DefaultInfoCard */}
          {/*     icon={<MKBox component="img" src={invision} alt="invision" width="12%" />} */}
          {/*     title="Digital Product Design" */}
          {/*     description="Check out our proven methods, guides, and exercises that help make work better, and people happier." */}
          {/*     small */}
          {/*   /> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={6} lg={4} mb={3}> */}
          {/*   <DefaultInfoCard */}
          {/*     icon={<MKBox component="img" src={slack} alt="slack" width="12%" />} */}
          {/*     title="Better Communication" */}
          {/*     description="Check out our proven methods, guides, and exercises that help make work better, and people happier." */}
          {/*     small */}
          {/*   /> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={6} lg={4} mb={3}> */}
          {/*   <DefaultInfoCard */}
          {/*     icon={<MKBox component="img" src={webdev} alt="webdev" width="12%" />} */}
          {/*     title="Logo design" */}
          {/*     description="Check out our proven methods, guides, and exercises that help make work better, and people happier." */}
          {/*     small */}
          {/*   /> */}
          {/* </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default HowItWorks;
