
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// MKit React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/cs/CS-screens/7.png";
function NoCustData() {


  return (
    <MKBox
      // display="flex"
      alignItems="center"
      // sx={{  }}
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        textAlign: { xs: "center" }
      }}
    >

          <MKTypography variant="h5" color="white" mb={1}>
            No Submission Data stays on the platform without customer consent.
          </MKTypography>

    </MKBox>
  );
}

export default NoCustData;
